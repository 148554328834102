// This is the scss entry file
import "../styles/index.scss";

// We can import Bootstrap JS instead of the CDN link, if you do not use
// Bootstrap, please feel free to remove it.
window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle.js");

// We can import other JS file as we like
// import "../components/sidebar";

// Libs
import 'lazysizes';

window.document.addEventListener("DOMContentLoaded", function () {
    // Changing header background when scroll
    const nav = document.getElementById('navbar');
    const header = document.querySelector('.section-hero h1');
    const navHeight = nav.getBoundingClientRect().height;

    function updateNavColor(entries) {
        const [entry] = entries;
        if(!entry.isIntersecting) {
            nav.classList.remove("bg-transparent");
            nav.classList.add("bg-light");
            nav.classList.add("shadow");
        } else {
            nav.classList.add("bg-transparent");
            nav.classList.remove("bg-light");
            nav.classList.remove("shadow");
        }
    }

    const headerObserver = new IntersectionObserver(updateNavColor, {
        root: null,
        threshold: 0.5,
        rootMargin: `-${navHeight}px`
    });

    headerObserver.observe(header);

    // Close header menu when click on link
    const navbarMenu = document.getElementById("navbarMenu");
    const navbarMenuLinks = document.querySelectorAll("#navbarMenu .nav-item");

    navbarMenuLinks.forEach((l) => {
        l.addEventListener('click', () => {
            if (navbarMenu.classList.contains("show")) {
                document.getElementById("navbarMenuToggler").click();
            }
        });
    });

    // Forms custom validation
    (() => {
        'use strict';
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        const forms = document.querySelectorAll('.needs-validation');

        // Loop over them and prevent submission
        Array.from(forms).forEach(form => {
            let submitButton = form.querySelector("button[type='submit']");
            form.addEventListener('submit', event => {
                event.preventDefault();
                submitButton.setAttribute("disabled", "");
                if (!form.checkValidity()) {
                    event.stopPropagation();
                    submitButton.removeAttribute("disabled");
                } else {
                    window.formSubmitCallback(function (success) {
                        if (!success) {
                            // Handle a failed submission
                            // todo here can be some message about sending fail
                            // Re-enable submit button if the submission failed
                            submitButton.removeAttribute("disabled");
                        }
                        // Success mechanics made in window.formSubmitCallback
                    });
                }

                form.classList.add('was-validated');
            }, false);
        });
    })();
});
